import { graphql, Link } from 'gatsby';
import { shape, string, arrayOf } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { resolve } from '../../../helpers/urls';
import { Content } from '../../common/content';
import { Expandable, Label, Option } from '../../common/expandable';
import { FluidPicture } from '../../common/picture';
import './product.scss';

const bem = new BEMHelper({
  name: 'content-product-heading',
});

export const ProductHeading = ({
  product,
  variant,
}) => {
  const { t } = useTranslation();
  const [department] = product.departments;
  const [need] = product.need;
  const [icon] = need.icon;

  return (
    <Content {...bem(null, { 'with-variants': product.variants.length > 1 })} useOwnSpacing>
      <div {...bem('wrapper')}>
        <h1 {...bem('headline')}>{product.title}</h1>
        <div {...bem('image-container')} aria-label={t('Proprietary Methods {{ title }}', { title: product.need[0].title })}>
          <FluidPicture
            role="presentation"
            {...icon}
          />
        </div>
        {product.variants.length > 1 && (
          <div {...bem('expandable')}>
            <p id={bem('label').className} {...bem('label')}>
              {t('Product variant:')}
            </p>
            <Expandable
              label={t('Product variant:')}
              aria-labelledby={bem('label').className}
            >
              <Label>{variant.label}</Label>
              {product.variants
                .filter(({ slug: variantSlug }) => variantSlug !== variant.slug)
                .map(({ label: variantLabel, slug: variantSlug }) => (
                  <Option key={variantSlug}>
                    <Link
                      to={resolve('products:detail', {
                        departmentSlug: department.slug,
                        productSlug: product.slug,
                        variantSlug,
                      })}
                      {...bem('link')}
                    >
                      {variantLabel}
                    </Link>
                  </Option>
                ))}
            </Expandable>
          </div>
        )}
      </div>
    </Content>
  );
};

ProductHeading.propTypes = {
  product: shape({
    slug: string.isRequired,
    title: string.isRequired,
    need: arrayOf(shape({
      slug: string.isRequired,
      title: string.isRequired,
      icon: arrayOf(shape({
        url: string.isRequired,
        mimeType: string.isRequired,
      })),
    })),
    variants: arrayOf(shape({
      slug: string.isRequired,
      label: string.isRequired,
    })).isRequired,
  }).isRequired,
  variant: shape({
    slug: string.isRequired,
    label: string.isRequired,
  }).isRequired,
};

export const fragments = graphql`
  fragment ProductHeadingProductFragment on CraftGQL_products_product_Entry {
    slug
    title
    need: productNeed(limit: 1) {
      ...on CraftGQL_needs_needs_Entry {
        slug
        title
        icon: productNeedIcon(limit: 1) {
          ...Picture
          url
          mimeType
        }
      }
    }
    variants: productVariants {
      ...on CraftGQL_variants_variants_Entry {
        slug
        label: variantLabel
      }
    }
    departments: productDepartment {
      ...on CraftGQL_departments_department_Entry {
        slug
      }
    }
  }

  fragment ProductHeadingVariantFragment on CraftGQL_variants_variants_Entry {
    slug
    label: variantLabel
  }
`;
