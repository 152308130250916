import { Link, graphql } from 'gatsby';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';

import { pictureFluidType } from '../../../types/picture';
import { Content } from '../content';
import { FluidPicture } from '../picture';
import './product.scss';

const bem = new BEMHelper({
  name: 'common-teaser-product',
});

export const commonProductTeaserFragment = graphql`
  fragment CommonProductTeaserFragment on CraftGQL_assets_Asset {
    ...Picture
    fluid_0: url(width: 100, height: 100, mode: "crop", position: "") # 0.25
    fluid_1: url(width: 200, height: 200, mode: "crop", position: "") # 0.5
    fluid_2: url(width: 400, height: 400, mode: "crop", position: "") # max width
    fluid_3: url(width: 600, height: 600, mode: "crop", position: "") # 1.5
    fluid_4: url(width: 800, height: 800, mode: "crop", position: "") # 2
  }
`;

export const TeaserProduct = ({
  title, need, image, link, className,
}) => {
  const { t } = useTranslation();
  return (
    <Content {...bem(null, null, className)} useOwnSpacing>
      <div {...bem('wrapper')}>
        <h2 {...bem('headline')}>{title}</h2>
        {need && <small {...bem('need')}>{need}</small>}
      </div>
      <FluidPicture
        {...image}
        width={400}
        height={400}
        maxWidth={400}
        widths={[100, 200, 400, 600, 800]}
        imgStyle={{
          objectFit: 'contain',
          objectPosition: '',
          position: '',
        }}
        role="presentation"
        {...bem('image')}
      />
      <Link
        {...bem('link')}
        to={link}
        title={t('Click here to open {{ product }}', { product: title })}
        aria-label={t('Show product')}
      />
    </Content>
  );
};

TeaserProduct.propTypes = {
  title: string.isRequired,
  need: string,
  image: pictureFluidType.isRequired,
  link: string.isRequired,
  className: string,
};

TeaserProduct.defaultProps = {
  need: null,
  className: null,
};
