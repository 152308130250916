import { string, bool } from 'prop-types';
import React, {
  Children,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import BEMHelper from 'react-bem-helper';

import { childrenType } from '../../../types';
import { Label } from './label';
import { Option } from './option';
import './expandable.scss';

const bem = new BEMHelper({
  name: 'expandable',
});

const keys = {
  esc: 27,
  up: 38,
  right: 39,
  down: 40,
  left: 37,
  tab: 9,
  pageUp: 33,
  pageDown: 34,
};

export const Expandable = ({
  label, isOpen, className, children, ...props
}) => {
  const expandable = useRef(null);
  const menu = useRef(null);
  const [isListOpen, setIsListOpen] = useState(false);
  const [selectableOptions, setSelectableOptions] = useState([]);
  const [count, setCount] = useState(0);

  const childs = useMemo(() => Children.toArray(children), [children]);
  const labels = useMemo(() => childs.filter(({ type }) => type === Label), [childs]);
  const options = useMemo(() => childs.filter(({ type }) => type === Option), [childs]);
  const forbidden = useMemo(
    () => childs.filter(({ type }) => type !== Label && type !== Option),
    [childs],
  );

  if (labels.length === 0) {
    throw new Error('<Expandable> needs a <Label>.');
  }

  if (options.length === 0) {
    throw new Error('<Expandable> needs at least one <Option>.');
  }

  if (forbidden.length > 0) {
    throw new Error('<Expandable> only support children of <Label> and <Option>.');
  }

  const toggleExpandable = () => {
    setCount(0);
    setIsListOpen(!isListOpen);
  };

  useEffect(() => {
    setIsListOpen(isOpen);
    setCount(0);
  }, [isOpen]);

  useEffect(() => {
    // select all interactive elements
    const interactive = menu.current.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
    setSelectableOptions(interactive);
  }, []);

  useEffect(() => {
    if (count > 0) {
      selectableOptions[count - 1].focus();
    }
  }, [count]);

  const handleClickOutside = useCallback((event) => {
    if (!expandable.current.contains(event.target)) {
      setIsListOpen(false);
    }
  }, [setIsListOpen]);

  useEffect(() => {
    if (isListOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isListOpen]);

  const onOptionArrow = (e, direction) => {
    if (direction === 'up') {
      // Set focus to the previous option.
      // If it's the first menu option, select the last option.
      if (count === 1) {
        setCount(selectableOptions.length);
      } else {
        setCount((previousCount) => previousCount - 1);
      }
    } else if (direction === 'down') {
      // Focus the next menu option.
      // If it’s the last menu option, select the first option.
      if (count === (selectableOptions.length)) {
        setCount(1);
      } else {
        setCount((previousCount) => previousCount + 1);
      }
    }
    e.preventDefault();
  };

  const onKeyDown = useCallback((e) => {
    switch (e.keyCode) {
      case keys.up:
        onOptionArrow(e, 'up');
        break;
      case keys.down:
        onOptionArrow(e, 'down');
        break;
      case keys.esc:
        setIsListOpen(false);
        break;
      case keys.tab:
        setIsListOpen(false);
        break;
      default:
        break;
    }
  });

  return (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onKeyDown={onKeyDown}
      ref={expandable}
      {...bem(null, { open: isListOpen }, className)}
    >
      <button
        aria-haspopup="true"
        aria-expanded={isListOpen}
        type="button"
        onClick={toggleExpandable}
        {...bem('button')}
        {...props}
      >
        {labels[0]}
        <span aria-hidden="true" {...bem('icon')} />
      </button>
      <ul role="menu" ref={menu} aria-label={label} tabIndex="-1" {...bem('options')}>
        {options}
      </ul>
    </div>
  );
};

Expandable.propTypes = {
  label: string.isRequired,
  isOpen: bool,
  children: childrenType.isRequired,
  className: string,
};

Expandable.defaultProps = {
  isOpen: false,
  className: null,
};
