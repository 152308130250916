export const postForm = async (formName, values) => {
  const body = { 'form-name': formName, ...values };

  const result = await window.fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: Object
      .entries(body)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&'),
  });

  return result;
};
