import { graphql } from 'gatsby';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';

import { useAuth } from '../../../hooks/useAuth';
import { useShop } from '../../../hooks/useShop';
import { Request as RequestForm } from '../../common/auth/request';
import { WithoutLogin } from '../../common/auth/states';
import { Button } from '../../common/button';
import { Content } from '../../common/content';

import './request.scss';

const bem = new BEMHelper({
  name: 'content-request',
});

const REGISTRATION_ENABLED = process.env.GATSBY_REGISTRATION_ENABLED === 'true';

export const Request = ({ id }) => {
  const { t } = useTranslation();
  const { openLogin, openRequestAccess } = useAuth();
  const { isEnabled: isShopEnabled } = useShop();

  return (
    <WithoutLogin>
      <Content {...bem()}>
        <div {...bem('wrapper')}>
          <div {...bem('content')}>
            <h2 {...bem('headline')}>
              {isShopEnabled
                ? t('Sign in to see all products details and order online')
                : t('Sign in to see all products details')}
            </h2>
            {/* we have to include the form in here so that it gets picked up by netlify by SSR */}
            <div role="presentation" aria-hidden="true" style={{ display: 'none' }}>
              <RequestForm id={id} />
            </div>
            <div {...bem('actions')}>
              { REGISTRATION_ENABLED && (
                  <Button
                    {...bem('button')}
                    variant="primary"
                    title={t('Click here to request access')}
                    onClick={openRequestAccess}
                  >
                    {t('Request access')}
                  </Button>
              )}
              <Button
                {...bem('button')}
                variant="secondary"
                title={t('Click here to sign in')}
                onClick={openLogin}
              >
                {t('Sign in')}
              </Button>
              {!REGISTRATION_ENABLED && (
                <div {...bem('registration-inactive')}><em>Registration is only possible on our B2B platform.<br />Please <a href={'https://shop.medskin-suwelack.com/account/register'}>follow this link</a> to register.</em></div>
              )}
            </div>
          </div>
        </div>
      </Content>
    </WithoutLogin>
  );
};

export const fragment = graphql`
  fragment RequestContentFragment on CraftGQL_pageContents_requestAccess_BlockType {
    id
  }
`;

Request.propTypes = {
  id: string.isRequired,
};
