import React from 'react';
import BEMHelper from 'react-bem-helper';
import { childrenType } from '../../../types';

const bem = new BEMHelper({
  name: 'expandable',
});

export const Label = ({
  children, ...props
}) => (
  <span {...bem('label')} {...props}>{children}</span>
);

Label.propTypes = {
  children: childrenType.isRequired,
};
