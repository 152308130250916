import React from 'react';
import { string } from 'prop-types';
import BEMHelper from 'react-bem-helper';
import { childrenType } from '../../../types';
import './expandable.scss';

const bem = new BEMHelper({
  name: 'expandable',
});

export const Option = ({
  children, className, ...props
}) => (
  <li {...props} {...bem('option', null, className)}>
    {children}
  </li>
);

Option.propTypes = {
  children: childrenType.isRequired,
  className: string,
};

Option.defaultProps = {
  className: null,
};
