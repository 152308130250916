import { graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { resolve } from '../../../../helpers/urls';
import { pictureFluidType } from '../../../../types/picture';
import { Content } from '../../../common/content';
import { TeaserProduct } from '../../../common/product';
import { Button } from '../../../common/button';
import { Carousel } from '../../../common/carousel';
import './list.scss';

const bem = new BEMHelper({
  name: 'content-productlist',
});

export const Productlist = ({ headline, products, reference }) => {
  const { t } = useTranslation();

  if (products.length === 0) {
    return null;
  }

  const wrapperClass = headline ? bem() : bem(null,'no-headline');

  return (
    <Content {...wrapperClass}>
      {headline && (<h2 {...bem('headline')}>{headline}</h2>)}

      <Carousel
        {...bem('carousel')}
        tagName="ul"
      >
        {products.map(({
          slug,
          title,
          need: [need],
          variant: [variant],
          department: [department],
        }) => {
          // References to variant or department might not exist (depending on
          // published state for example). In this case do not render the teaser:
          if (!variant || !department) {
            return null;
          }

          const link = resolve('products:detail', {
            productSlug: slug,
            departmentSlug: department.slug,
            variantSlug: variant.slug,
          });
          return (
            <li key={slug} {...bem('list-item')}>
              <TeaserProduct title={title} need={need.title} image={variant.image[0]} link={link} />
            </li>
          );
        })}
      </Carousel>

      {reference?.element?.slug && (
        <Button
          {...bem('link')}
          variant="secondary"
          title={t('Click here to see all products')}
          to={resolve('pages:detail', {
            pageSlug: reference.element.slug,
            departmentSlug: reference.element.departments[0]?.slug,
          })}
        >
          {reference.text || t('See all products')}
        </Button>
      )}
    </Content>
  );
};

Productlist.propTypes = {
  headline: string,
  products: arrayOf(shape({
    title: string.isRequired,
    slug: string.isRequired,
    need: arrayOf(shape({
      title: string.isRequired,
    })).isRequired,
    variant: arrayOf(shape({
      slug: string.isRequired,
      label: string.isRequired,
      image: arrayOf(pictureFluidType).isRequired,
    })),
  })).isRequired,
  reference: shape({
    text: string,
    element: shape({
      slug: string.isRequired,
      departments: arrayOf(shape({
        slug: string.isRequired,
      })).isRequired,
    }),
  }),
};

Productlist.defaultProps = {
  headline: undefined,
  reference: undefined,
};

export const fragment = graphql`
  fragment ProductlistContentFragment on CraftGQL_pageContents_productList_BlockType {
    headline
    products {
      ...on CraftGQL_products_product_Entry {
        title
        slug

        need: productNeed(limit: 1) {
          ...on CraftGQL_needs_needs_Entry {
            title
          }
        }

        variant: productVariants(limit: 1) {
          ...on CraftGQL_variants_variants_Entry {
            slug
            label: variantLabel
            image: variantImage(limit: 1) {
             ...CommonProductTeaserFragment
            }
          }
        }

        department: productDepartment {
          ...on CraftGQL_departments_department_Entry {
            slug
          }
        }
      }
    }

    reference {
      text
      element {
        ...on CraftGQL_pages_pages_Entry {
          slug: pageSlug
          departments: pageDepartments {
            ...on CraftGQL_departments_department_Entry {
              slug
            }
          }
        }
      }
    }
  }
`;
