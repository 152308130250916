import { graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import parse from 'html-react-parser';

import { Carousel } from '../../common/carousel';
import { Content } from '../../common/content';
import { FluidPicture } from '../../common/picture';
import { pictureFluidType } from '../../../types/picture';
import './details.scss';

const bem = new BEMHelper({
  name: 'content-product-details',
});

export const ProductDetails = ({
  product: {
    slug,
  },
  variant: {
    introduction,
    description,
    images,
  },
}) => (
  <Content {...bem()} useOwnSpacing>
    <div {...bem('wrapper')}>
      {introduction && (<p {...bem('introduction')}>{introduction}</p>)}
      <div {...bem('description')} id={`${slug}--description`}>{ parse(description) }</div>
    </div>
    <div {...bem('images')}>
      {images.length === 1
        ? (
          <FluidPicture
            {...images[0]}
            width={600}
            height={600}
            maxWidth={600}
            widths={[150, 300, 600, 900, 1200]}
            {...bem('image')}
          />
        )
        : (
          <Carousel {...bem('carousel')} tagName="ul">
            {images.map((image) => (
              <li key={image.fluid_0} {...bem('image__item')}>
                <FluidPicture
                  {...image}
                  width={600}
                  height={600}
                  maxWidth={600}
                  widths={[150, 300, 600, 900, 1200]}
                  {...bem('image')}
                />
              </li>
            ))}
          </Carousel>
        )}
    </div>
  </Content>
);

ProductDetails.propTypes = {
  product: shape({
    slug: string.isRequired,
  }).isRequired,
  variant: shape({
    introduction: string,
    description: string.isRequired,
    images: arrayOf(pictureFluidType).isRequired,
  }).isRequired,
};

export const fragments = graphql`
  fragment ProductDetailsVariantFragment on CraftGQL_variants_variants_Entry {
    introduction: variantIntroduction
    description: variantDescription
    images: variantImage {
      ...Picture
      fluid_0: url(width: 150, height: 150, mode: "crop", position: "") # 0.25
      fluid_1: url(width: 300, height: 300, mode: "crop", position: "") # 0.5
      fluid_2: url(width: 600, height: 600, mode: "crop", position: "") # max width
      fluid_3: url(width: 900, height: 900, mode: "crop", position: "") # 1.5
      fluid_4: url(width: 1200, height: 1200, mode: "crop", position: "") # 2
    }
  }
`;
