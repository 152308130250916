import { graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { pictureFluidType } from '../../../types/picture';
import { Productlist } from '../../content/product/list';
import './related.scss';

export const ProductRelated = ({
  product,
}) => {
  const { t } = useTranslation();
  const { relatedHeadline: headline, related } = product;

  if (related.length === 0) {
    // No related products available
    return null;
  }

  return (
    <Productlist headline={headline || t('Related products')} products={related} />
  );
};

ProductRelated.propTypes = {
  product: shape({
    relatedHeadline: string,
    related: arrayOf(shape({
      slug: string.isRequired,
      title: string.isRequired,
      variant: arrayOf(shape({
        slug: string,
        label: string,
        image: arrayOf(pictureFluidType).isRequired,
      })).isRequired,
      department: arrayOf(shape({
        slug: string.isRequired,
      })).isRequired,
    })).isRequired,
  }).isRequired,
};

export const fragments = graphql`
  fragment ProductRelatedProductFragment on CraftGQL_products_product_Entry {
    relatedHeadline: productRelatedProductsHeadline

    related: productRelatedProducts {
      ...on CraftGQL_products_product_Entry {
        slug
        title

        need: productNeed(limit: 1) {
          ...on CraftGQL_needs_needs_Entry {
            title
          }
        }

        variant: productVariants(limit: 1) {
          ...on CraftGQL_variants_variants_Entry {
            slug
            label: variantLabel
            image: variantImage(limit: 1) {
              ...CommonProductTeaserFragment
            }
          }
        }

        department: productDepartment {
          ...on CraftGQL_departments_department_Entry {
            slug
          }
        }
      }
    }
  }
`;
