import { Form, Formik } from 'formik';
import { graphql, Link } from 'gatsby';
import {
  bool, number, shape, string,
} from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { BehindLogin } from '../../common/auth/states';
import { Button } from '../../common/button';
import { Input } from '../../common/form';
import { PriceFormat } from '../../common/price';
import { Content } from '../../common/content';
import { resolve } from '../../../helpers/urls';
import { useCart, MAX_UNITS } from '../../../hooks/useCart';
import { useNotification } from '../../../hooks/useNotification';
import { CURRENCY, useShop } from '../../../hooks/useShop';
import parse from 'html-react-parser';
import './addtocart.scss';

const bem = new BEMHelper({
  name: 'content-product-addtocart',
});

const useSchema = () => {
  const { t } = useTranslation();

  return useMemo(
    () => Yup.object().shape({
      quantity: Yup
        .number()
        .min(1, t('Please choose a correct quantity.'))
        .max(MAX_UNITS, t('Please choose a smaller quantity.'))
        .required(t('Please choose a quantity.')),
    }),
    [],
  );
};

export const ProductAddToCart = ({ product, variant }) => {
  const { t } = useTranslation();
  const { sample, moq, moqIncrement, disclaimer } = variant;
  const { isEnabled } = useShop();
  const { isEmpty, addToCart, canAdd } = useCart();
  const { addNotification } = useNotification();
  const schema = useSchema();
  const canAddRegular = canAdd(product.uid, variant.uid, false);
  const canAddSample = canAdd(product.uid, variant.uid, true);

  console.log("variant", variant)

  // MDS-3
  //console.log("moq:" + moq + ' variant.moq: ' + variant.moq);
  const minimumOrderQuantity = (typeof moq === 'number') ? moq : 1;
  const quantityInputType = (typeof moqIncrement === 'number') ? 'select' : 'input';

  const onAddQuantity = useCallback(
    ({ quantity }) => {
      try {
        addToCart(product.uid, variant.uid, quantity, false, variant.moq, variant.moqIncrement);
        addNotification('success', t('{{ count }} items added to cart.', { count: quantity }));
      } catch (error) {
        addNotification('error', error.message);
      }
    },
    [addToCart, variant],
  );
  const onAddSample = useCallback(
    () => {
      try {
        addToCart(product.uid, variant.uid, 1, true);
        addNotification('success', t('Sample added to cart.'));
      } catch (error) {
        addNotification('error', error.message);
      }
    },
    [addToCart, variant],
  );

  if (!isEnabled) {
    return null;
  }

  return (
    <BehindLogin>
      <Content {...bem()}>
        <Formik
          initialValues={{ quantity: minimumOrderQuantity }}
          validationSchema={schema}
          onSubmit={onAddQuantity}
        >
          {({ values, errors, handleBlur }) => (
            <Form {...bem('form')} noValidate>
              <div {...bem('details')}>
                <div {...bem('fields')}>
                  <div {...bem('heading')}>
                    <h2 {...bem('headline')}>{t('Add to cart')}</h2>
                    <p {...bem('price')}>
                      <span {...bem('price__label')}>{`${t('Cost per unit')}: `}</span>
                      <span {...bem('price__value')}>
                        <PriceFormat currency={CURRENCY} value={variant.price} />
                        <sup><a href={`#${product.slug}--description`}>&#42;</a></sup>
                      </span>
                    </p>
                  </div>
                  <div {...bem('quantity')}>
                    <Input
                      as={quantityInputType}
                      children={moqIncrement &&
                        new Array(10).fill('', 0, 10).map((p,i) =>
                          <option key={`quantityOption_` + i} value={parseInt(moq) + parseInt(i * moqIncrement)}>{parseInt(moq) + parseInt(i * moqIncrement)}</option>
                        )
                      }
                      id="quantity"
                      name="quantity"
                      type="number"
                      inputMode="numeric"
                      pattern="[1-9][0-9]+"
                      min="1"
                      max={MAX_UNITS}
                      maxLength="4"
                      step="1"
                      label={t('Quantity')}
                      value={values.quantity}
                      error={errors.quantity}
                      disabled={!canAddRegular}
                      onBlur={handleBlur}
                    />
                    {minimumOrderQuantity > 1
                      ?
                    (
                      <small>
                      {`${t('Minimum order amount')}: `} {minimumOrderQuantity}
                      </small>
                    )
                      : null
                      }
                  </div>
                </div>
              </div>

              <div {...bem('controls')}>
                <Button
                  {...bem('controls__add-by-quantity')}
                  type="submit"
                  title={t('Add {{ count }} product to cart', { count: values.quantity })}
                  disabled={!canAddRegular}
                >
                  {t('Add to cart')}
                </Button>

                {sample
                  ? (
                    <Button
                      {...bem('controls__add-sample')}
                      type="button"
                      variant="secondary"
                      title={t('Add a sample to cart')}
                      disabled={!canAddSample}
                      onClick={onAddSample}
                    >
                      {t('Request sample')}
                    </Button>
                  )
                  : null}
              </div>

              <div {...bem('notifications')}>
                {errors.quantity
                  ? (
                    <p role="alert" {...bem('notification', 'error')}>
                      <strong>{errors.quantity}</strong>
                    </p>
                  )
                  : null}
                {(!canAddSample && !canAddRegular)
                  ? (
                    <p role="log" {...bem('notification', 'note')}>
                      <strong>{t('Remove the sample of this product from your cart if you wish to purchase more.')}</strong>
                    </p>
                  )
                  : null}
              </div>

              <div {...bem('links')}>
                {!isEmpty
                  ? (
                    <Link
                      {...bem('link')}
                      to={resolve('shop:cart')}
                      title={t('Click here to see your cart')}
                    >
                      {t('Go to cart')}
                    </Link>
                  )
                  : null}
              </div>
            </Form>
          )}
        </Formik>

        { disclaimer ?
          (
          <div {...bem('order-disclaimer', 'wrapper')}>
            <div {...bem('order-disclaimer', 'content')}>
              {parse(disclaimer)}
            </div>
          </div>
          )
          : null}

      </Content>
    </BehindLogin>
  );
};

ProductAddToCart.propTypes = {
  product: shape({
    uid: string.isRequired,
  }).isRequired,
  variant: shape({
    uid: string.isRequired,
    price: number.isRequired,
    sample: bool.isRequired,
  }).isRequired,
};

export const fragments = graphql`
  fragment ProductAddToCartProductFragment on CraftGQL_products_product_Entry {
    uid
  }

  fragment ProductAddToCartVariantFragment on CraftGQL_variants_variants_Entry {
    uid
    price: variantPrice
    sample: variantSample
    moq: variantMinimumOrderQuantity
    moqIncrement: variantQuantityIncrement
    disclaimer: variantOrderDisclaimer
  }
`;
