import { number, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const PriceFormat = ({ currency, value }) => {
  const { t } = useTranslation();

  if (value === 0) {
    return (<>{t('Free')}</>);
  }

  // @TODO: Should we use the Intl API here to format numbers correctly?
  const money = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
  //const [num, dec] = value.toString().split('.');
  //const decimal = (dec ? `${dec}0` : '00').substr(0, 2);
  return (<>{`${money}`}</>);
};

PriceFormat.propTypes = {
  currency: string.isRequired,
  value: number.isRequired,
};
