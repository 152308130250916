import { arrayOf, string, shape } from 'prop-types';
import { graphql } from 'gatsby';
import React from 'react';

import { Renderer } from '../../components/common/renderer';
import { CONTENT_MAPPING } from '../../components/content/mapping';
import { Request } from '../../components/content/request';
import { ProductHeading } from '../../components/headings/product';
import { ProductAddToCart } from '../../components/product/addtocart';
import { ProductDetails } from '../../components/product/details';
import { ProductRelated } from '../../components/product/related';
import { Sitemeta } from '../../components/core/sitemeta';
import { entryType, responseType } from '../../types';

export const query = graphql`
  query ProductVariantPage(
    $productId: [CraftGQL_QueryArgument]!,
    $variantId: [CraftGQL_QueryArgument]!
  ) {
    response: craftgql {
      product: entry(id: $productId) {
        ...on CraftGQL_products_product_Entry {
          ...ProductHeadingProductFragment
          ...ProductAddToCartProductFragment
          ...ProductContentsFragment
          ...ProductRelatedProductFragment
          title
          departments: productDepartment {
            ...on CraftGQL_departments_department_Entry {
              color: departmentColor
            }
          }
        }
      }
      variant: entry(id: $variantId) {
        ...on CraftGQL_variants_variants_Entry {
          ...ProductHeadingVariantFragment
          ...ProductDetailsVariantFragment
          ...ProductAddToCartVariantFragment
        }
      }
    }
  }
`;

const ProductVariantPage = ({ data }) => {
  const { product, variant } = data.response;
  const [department] = product.departments;
  const { contents } = product;

  return (
    <>
      <Sitemeta
        title={`${product.title} ${variant.label}`}
        description={variant.description}
        color={department.color}
      />
      <ProductHeading product={product} variant={variant} />
      <ProductDetails product={product} variant={variant} />
      <ProductAddToCart product={product} variant={variant} />
      <Renderer contents={contents} mapping={CONTENT_MAPPING} />
      <ProductRelated product={product} variant={variant} />
      <Request id="section-request-access" />
    </>
  );
};

ProductVariantPage.propTypes = {
  ...responseType({
    product: entryType({
      title: string.isRequired,
      departments: arrayOf(shape({
        color: string.isRequired,
      })).isRequired,
    }),
  }),
};

export default ProductVariantPage;
