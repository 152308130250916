import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BEMHelper from 'react-bem-helper';
import { string, func } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { resolve } from '../../../../helpers/urls';
import { postForm } from '../../../../helpers/netlify';
import { useNotification } from '../../../../hooks/useNotification';
import { Button } from '../../button';
import {Input, Checkbox, Countryselect} from '../../form';
import './request.scss';

const bem = new BEMHelper({
  name: 'auth-request',
});

const query = graphql`
  query {
    response: craftgql {
      contact: globalSet(handle: "contact") {
        ...on CraftGQL_contact_GlobalSet {
          privacyterms: contactPrivacyTermsPage {
            ...on CraftGQL_pages_pages_Entry {
              title
              slug: pageSlug
              departments: pageDepartments {
                ...on CraftGQL_departments_department_Entry {
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useSchema = () => {
  const { t } = useTranslation();

  return useMemo(
    () => Yup.object().shape({
      firstname: Yup
        .string()
        .min(3, t('Your first name should contain at least 3 letters.'))
        .required(t('Your first name is required.')),
      lastname: Yup
        .string()
        .min(3, t('Your last name should contain at least 3 letters.'))
        .required(t('Your last name is required.')),
      company: Yup
        .string()
        .min(2, t('Your company name should contain at least 2 letters.'))
        .required(t('Company name is required.')),
      companyaddress: Yup
        .string()
        .min(1, t('Your company address should contain at least 5 letters.'))
        .required(t('Company address is required.')),
      companywebsite: Yup
        .string()
        .min(5, t('Your company URL should contain at least 5 characters'))
        .required(t('Company website is required.')),
      country: Yup
        .string()
        .required(t('Please select a country.')),
      email: Yup
        .string()
        .email(t('Your E-Mail address is invalid.'))
        .required(t('Your E-Mail address is required.')),
      password: Yup
        .string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .required(t('Your password needs to be at least 8 characters long and have at least 1 lowercase, 1 uppercase letter and 1 number.')),
      privacy: Yup
        .boolean()
        .oneOf(
          [true],
          t('You need to accept our privacy terms.')),
    }),
    [],
  );
};

const NETLIFY_FORM_NAME = 'Request access';

export const Request = ({ id, onRequest }) => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { response } = useStaticQuery(query);
  const { contact } = response;
  const schema = useSchema();

  const privacytermsLink = resolve('pages:detail', {
    pageSlug: contact.privacyterms[0].slug,
    departmentSlug: contact.privacyterms[0].departments[0]?.slug,
  });

  const onSubmit = useCallback((values, { setSubmitting }) => {
    const submit = async () => {
      try {
        await postForm(NETLIFY_FORM_NAME, values);
        addNotification('success', t('Thank you for your registration. We will review your request and notify you as soon as it was approved.'));
        onRequest();
      } catch (error) {
        addNotification('error', t('An error occurred'));
        setSubmitting(false);
      }
    };
    submit();
  }, []);

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{ firstname: '', lastname: '', company: '', email: '', country: '' }}
    >
      {({
        values,
        errors,
        handleReset,
        handleSubmit,
        touched,
        isSubmitting,
        isValid,
        handleChange,
        handleBlur,
      }) => (
        <form onReset={handleReset} onSubmit={handleSubmit} data-netlify="true" name={NETLIFY_FORM_NAME} {...bem()}>
          <input type="hidden" name="form-name" value={NETLIFY_FORM_NAME} />
          <div {...bem('details')}>
            <h2 {...bem('headline')}>{t('Request access')}</h2>
            {errors.general && (<p {...bem('error')} role="alert">{errors.general}</p>)}
            <p {...bem('mandatory')}><em>{t('All fields are mandatory')}</em></p>
          </div>

          <div {...bem('form')}>
            <div {...bem('fifty-fifty')}>
              <Input
                label={t('First Name')}
                type="text"
                name="firstname"
                id={`${id}-firstname`}
                value={values.firstname}
                error={errors.firstname && touched.firstname && errors.firstname}
                onBlur={handleBlur}
                disabled={isSubmitting}
                halfSize={true}
                required
              />
              <Input
                label={t('Last Name')}
                type="text"
                name="lastname"
                id={`${id}-lastname`}
                value={values.lastname}
                error={errors.lastname && touched.lastname && errors.lastname}
                onBlur={handleBlur}
                disabled={isSubmitting}
                halfSize={true}
                required
              />
            </div>
            <Input
              label={t('Company')}
              type="text"
              name="company"
              id={`${id}-company`}
              value={values.company}
              error={errors.company && touched.company && errors.company}
              onBlur={handleBlur}
              required
            />
            <Input
              label={t('Company address')}
              type="text"
              name="companyaddress"
              id={`${id}-companyaddress`}
              value={values.companyaddress}
              error={errors.companyaddress && touched.companyaddress && errors.companyaddress}
              onBlur={handleBlur}
              required
            />
            <Input
              label={t('Company website')}
              type="text"
              name="companywebsite"
              id={`${id}-companywebsite`}
              value={values.companywebsite}
              error={errors.companywebsite && touched.companywebsite && errors.companywebsite}
              onBlur={handleBlur}
              required
            />
            <Countryselect
              label={t('Country of Operation')}
              name="country"
              id={`${id}-country`}
              value={values.country}
              error={errors.country && touched.country && errors.country}
              handleChange={handleChange}
              onBlur={handleBlur}
              required
            />
            <Input
              label={t('Business E-Mail')}
              type="email"
              name="email"
              id={`${id}-email`}
              value={values.email}
              error={errors.email && touched.email && errors.email}
              onBlur={handleBlur}
              required
            />
            <Input
              label={t('Your desired password')}
              type="text"
              name="password"
              id={`${id}-password`}
              value={values.password}
              error={errors.password && touched.password && errors.password}
              onBlur={handleBlur}
              required
            />
            <Checkbox
              id={`${id}-privacy`}
              name="privacy"
              error={errors.privacy && touched.privacy && errors.privacy}
              onBlur={handleBlur}
              required
            >
              <Trans i18nKey="terms_and_conditions">
                I have read the MedSkin Solutions Dr. Suwelack AG
                <a
                  href={privacytermsLink}
                  target="_blank"
                  rel="noopener"
                  {...bem('note__link')}
                >
                  privacy terms
                </a>
                and agree to them.
              </Trans>
            </Checkbox>
            <small {...bem('note')}>
              <Trans i18nKey="legal_note_medskin">
                Our online platform is exclusively for Skin Care brands
                which aim to integrate our products into their brand portfolio.
              </Trans>
            </small>
            <small {...bem('note')}>
              <Trans i18nKey="legal_note_netlify">
                We use Netlify as our hosting provider. By clicking below
                to submit, you acknowledge that your information will be
                transferred to Netlify for processing.
                <a
                  href="https://www.netlify.com/privacy/"
                  target="_blank"
                  rel="noopener"
                  {...bem('note__link')}
                >
                  Learn more about Netlify privacy policy here
                </a>
              </Trans>
            </small>
            <div {...bem('submitsection')}>
              <Button
                {...bem('submit')}
                type="submit"
                title={t('Submit login access request')}
                disabled={!isValid || isSubmitting}
              >
                {t('Submit request')}
              </Button>

              <p {...bem('mandatory')}><em>{t('All fields are mandatory')}</em></p>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

Request.propTypes = {
  id: string.isRequired,
  onRequest: func,
};

Request.defaultProps = {
  onRequest: null,
};
